// import logo from './logo.svg';
import "./App.scss"

import React, { useContext, useEffect, useState } from "react"

// import Home from './pages/home';
// import { ContentContext } from "./context/pageContentContext"
import LogoAnimation from "./components/LogoAnimation"

// import Navigation from "./components/Navigation"
// import { SettingContext } from "./context/pageSettingContext"

// import { Route, BrowserRouter as Router, Switch } from "react-router-dom"

// import axios from "axios"
// import routes from "./routes"

function App() {
  //   const contentContext = useContext(ContentContext)
  // const settingContext = useContext(SettingContext)

  //   // useEffect( () => {
  //   // axios.defaults.baseURL = process.env.REACT_APP_API_URL;

  //   // },[ContentContext]);

  //   useEffect(() => {
  //     const fetchData = async () => {
  //       axios.defaults.headers.post["Content-Type"] =
  //         "application/json;charset=utf-8"
  //       axios.defaults.headers.get["Access-Control-Allow-Origin"] = "*"
  //       await axios
  //         .get(process.env.REACT_APP_API_URL + "/pages")
  //         .then((resp) => {
  //           // console.log(resp);
  //           contentContext.setData(resp.data)
  //           setTimeout(function () {
  //             settingContext.setLoading(false)
  //           }, 2000)
  //         })
  //         .catch((error) => {
  //           console.log(error)
  //           settingContext.setLoading(false)
  //         })
  //     }
  //     fetchData()
  //   }, [])

  const [loading, setLoading] = useState(true)

  let content = <LogoAnimation />
  //   if (settingContext.loading === false) {
  //     content = (
  //       <React.Suspense fallback={<LogoAnimation />}>
  //         <Router>
  //           <Navigation />
  //           <Switch>
  //             {routes.map(({ path, componentName, exact }, key) => (
  //               <Route
  //                 exact={exact}
  //                 key={componentName + key}
  //                 path={path}
  //                 component={componentName}
  //               />
  //             ))}
  //           </Switch>
  //         </Router>
  //       </React.Suspense>
  //     )
  //   }

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 3800)
  }, [])

  if (loading === false) {
    content = (
      <section
        style={{
          height: "100vh",
          width: "100vw",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>
          <h1>Hi there! </h1>
          <p>
            Am currently working to update the site, find me on Linkedin
            instead:
          </p>
          <a
            href="https://www.linkedin.com/in/rachet-khanal/"
            target="_blank"
            rel="nofollow noopener noreferrer"
          >
            <i
              className="fab fa-linkedin"
              style={{ marginRight: "0.25rem" }}
            ></i>
            Visit Linkedin
          </a>
        </div>
      </section>
    )
  }

  return <div className="App">{content}</div>
}

export default App
